<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align:right">
      <c-btn
        label="저장"
        icon="save"
        @btnClicked="saveData"/>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:25px !important;">
      <c-upload 
        :attachInfo="attachInfo"
        :editable="editable"
        :isPhotoView="true"
        label="작업결과 사진첨부">
      </c-upload>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'outernal-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'WO_RESULT',
        taskKey: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$set(this.attachInfo, 'taskKey', '1')
    },
    saveData() {
      this.$set(this.attachInfo, 'taskKey', '1')
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
  }
};
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
        staticStyle: { "text-align": "right" },
      },
      [
        _c("c-btn", {
          attrs: { label: "저장", icon: "save" },
          on: { btnClicked: _vm.saveData },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
        staticStyle: { "padding-top": "25px !important" },
      },
      [
        _c("c-upload", {
          attrs: {
            attachInfo: _vm.attachInfo,
            editable: _vm.editable,
            isPhotoView: true,
            label: "작업결과 사진첨부",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }